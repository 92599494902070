import { useSelector, useDispatch } from 'react-redux';
import Client from 'shopify-buy';
import Cookies from 'universal-cookie';
import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  gql
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const REACT_APP_SHOPIFY_STOREFRONT_TOKEN = 'f249368e8e1c14b2ceecc8279fadb5c7';

const authLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    'X-Shopify-Storefront-Access-Token': REACT_APP_SHOPIFY_STOREFRONT_TOKEN
  }
}));

const httpLink = createHttpLink({
  uri: 'https://checkout.merchforall.com/api/graphql'
});

const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});

// Creates the client with Shopify-Buy and store info
const client = Client.buildClient({
  storefrontAccessToken: REACT_APP_SHOPIFY_STOREFRONT_TOKEN,
  domain: 'checkout.merchforall.com'
});

const cookies = new Cookies();

const PRODUCT_FOUND = 'shopify/PRODUCT_FOUND';
const COLLECTION_FOUND = 'shopify/COLLECTION_FOUND';
const CHECKOUT_FOUND = 'shopify/CHECKOUT_FOUND';
const SHOP_FOUND = 'shopify/SHOP_FOUND';
const ADD_VARIANT_TO_CART = 'shopify/ADD_VARIANT_TO_CART';
const UPDATE_QUANTITY_IN_CART = 'shopify/UPDATE_QUANTITY_IN_CART';
const REMOVE_LINE_ITEM_IN_CART = 'shopify/REMOVE_LINE_ITEM_IN_CART';
const OPEN_CART = 'shopify/OPEN_CART';
const CLOSE_CART = 'shopify/CLOSE_CART';
const CART_COUNT = 'shopify/CART_COUNT';

const initialState = {
  isCartOpen: false,
  cartCount: 0,
  checkout: {},
  products: [],
  product: {},
  shop: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case COLLECTION_FOUND:
      return { ...state, products: action.payload };
    case PRODUCT_FOUND:
      return { ...state, product: action.payload };
    case CHECKOUT_FOUND:
      return { ...state, checkout: action.payload };
    case SHOP_FOUND:
      return { ...state, shop: action.payload };
    case ADD_VARIANT_TO_CART:
      return { ...state, checkout: action.payload };
    case UPDATE_QUANTITY_IN_CART:
      return { ...state, checkout: action.payload };
    case REMOVE_LINE_ITEM_IN_CART:
      return { ...state, checkout: action.payload };
    case OPEN_CART:
      return { ...state, isCartOpen: true };
    case CLOSE_CART:
      return { ...state, isCartOpen: false };
    case CART_COUNT:
      return { ...state, cartCount: action.payload };
    default:
      return state;
  }
};

// Gets a collection based on that collection's id
// id should be base64encoded
function getCollection(id) {
  const encodedID = btoa(`gid://shopify/Collection/${id}`);
  return (dispatch) => {
    apolloClient
      .query({
        variables: { id: encodedID, productsFirst: 50 },
        query: gql`
          fragment VariantFragment on ProductVariant {
            id
            title
            price {... on MoneyV2 {amount, currencyCode}}
						quantityAvailable
            priceV2 {
              amount
              currencyCode
            }
            weight
            available: availableForSale
            sku
            image {
              id
              transformedSrc(maxWidth: 440)
            }
            selectedOptions {
              name
              value
            }
          }
          fragment CollectionFragment on Collection {
            id
            handle
            title
            updatedAt
          }
          fragment ProductFragment on Product {
            id
            availableForSale
            descriptionHtml
            description
            handle
            productType
            title
            tags
						totalInventory
            options {
              id
              name
              values
            }
            images(first: 250) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              edges {
                cursor
                node {
                  id
                  transformedSrc(maxWidth: 440)
                }
              }
            }
            variants(first: 250) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              edges {
                cursor
                node {
                  ...VariantFragment
                }
              }
            }
          }
          query ($id: ID!, $productsFirst: Int!) {
            node(id: $id) {
              __typename
              ...CollectionFragment
              ... on Collection {
                id
                products(first: $productsFirst) {
                  pageInfo {
                    hasNextPage
                    hasPreviousPage
                  }
                  edges {
                    cursor
                    node {
                      ...ProductFragment
                    }
                  }
                }
              }
            }
          }
        `
      })
      .then((resp) => {
        const now = new Date();

        dispatch({
          type: COLLECTION_FOUND,
          payload: resp?.data?.node?.products?.edges
            ? resp?.data?.node?.products?.edges
              .map((edge) => ({
                ...edge.node,
                variants: edge?.node?.variants?.edges.map(
                  (edge) => edge.node
                ),
                images: edge?.node?.images?.edges.map((edge) => edge.node),
                endDate:
                  edge?.node?.tags && edge.node.tags.length > 1
                    ? // tags are returned in inverted order, so date tag is 1st element if present
                    new Date(edge.node.tags[0])
                    : undefined
              }))
              .filter(
                (product) =>
                  (product.endDate && new Date(product.endDate) > now) ||
                  !product.endDate
              )
            : []
        });
      });
  };
}

// Gets individual item based on id
function getProduct(id) {
  return async (dispatch) => {
    const resp = await client.product.fetch(id);
    dispatch({
      type: PRODUCT_FOUND,
      payload: resp
    });
    return resp;
  };
}

// Creates initial checkout state from Shopify
function checkout() {
  return (dispatch) => {
    const cartCookie = cookies.get('cart');

    if (cartCookie) {
      client.checkout.fetch(cartCookie).then((resp) => {
        if (resp.completedAt) {
          client.checkout.create().then((resp) => {
            const aWeekFromNow = new Date();
            aWeekFromNow.setDate(aWeekFromNow.getDate() + 7);

            cookies.set('cart', resp.id, { path: '/', expires: aWeekFromNow });

            dispatch({
              type: CHECKOUT_FOUND,
              payload: resp
            });
          });
        } else {
          dispatch({
            type: CHECKOUT_FOUND,
            payload: resp
          });
        }
      });
    } else {
      client.checkout.create().then((resp) => {
        const aWeekFromNow = new Date();
        aWeekFromNow.setDate(aWeekFromNow.getDate() + 7);

        cookies.set('cart', resp.id, { path: '/', expires: aWeekFromNow });

        dispatch({
          type: CHECKOUT_FOUND,
          payload: resp
        });
      });
    }
  };
}

// Gets Shopify store information
function shopInfo() {
  return (dispatch) => {
    client.shop.fetchInfo().then((resp) => {
      dispatch({
        type: SHOP_FOUND,
        payload: resp
      });
    });
  };
}

// Adds variants to cart/checkout
function addVariantToCart(checkoutId, lineItemsToAdd) {
  return async (dispatch) => {
    const response = await client.checkout.addLineItems(
      checkoutId,
      lineItemsToAdd
    );
    dispatch({
      type: ADD_VARIANT_TO_CART,
      payload: response
    });
    return response;
  };
}

// Helper to map options to variant
function variantForOptions(product, selectedOptions) {
  return client.product.helpers.variantForOptions(product, selectedOptions);
}

// Updates quantity of line items in cart and in checkout state
function updateQuantityInCart(lineItemId, quantity, checkoutId) {
  const lineItemsToUpdate = [
    { id: lineItemId, quantity: parseInt(quantity, 10) }
  ];

  return async (dispatch) => {
    const resp = await client.checkout.updateLineItems(
      checkoutId,
      lineItemsToUpdate
    );
    dispatch({
      type: UPDATE_QUANTITY_IN_CART,
      payload: resp
    });
    return resp;
  };
}

// Removes line item from cart and checkout state
function removeLineItemInCart(checkoutId, lineItemId) {
  return (dispatch) => {
    client.checkout.removeLineItems(checkoutId, [lineItemId]).then((resp) => {
      dispatch({
        type: REMOVE_LINE_ITEM_IN_CART,
        payload: resp
      });
    });
  };
}

// To close the cart
function handleCartClose() {
  return {
    type: CLOSE_CART
  };
}

// To open the cart
function handleCartOpen() {
  return {
    type: OPEN_CART
  };
}

// Set the count of items in the cart
function handleSetCount(count) {
  return {
    type: CART_COUNT,
    payload: count
  };
}

export function useShopify() {
  const dispatch = useDispatch();
  const cartStatus = useSelector(
    (appState) => appState.shopifyState.isCartOpen
  );
  const cartCount = useSelector((appState) => appState.shopifyState.cartCount);
  const products = useSelector((appState) => appState.shopifyState.products);
  const product = useSelector((appState) => appState.shopifyState.product);
  const featured = useSelector((appState) => appState.shopifyState.featured);
  const checkoutState = useSelector(
    (appState) => appState.shopifyState.checkout
  );
  const shopDetails = useSelector((appState) => appState.shopifyState.shop);
  const fetchProduct = (id) => dispatch(getProduct(id));
  const fetchCollection = (id) => dispatch(getCollection(id));
  const createCheckout = () => dispatch(checkout());
  const createShop = () => dispatch(shopInfo());
  const closeCart = () => dispatch(handleCartClose());
  const openCart = () => dispatch(handleCartOpen());
  const setCount = (count) => dispatch(handleSetCount(count));

  const addVariant = (checkoutId, lineItemsToAdd) =>
    dispatch(addVariantToCart(checkoutId, lineItemsToAdd));
  const updateQuantity = (lineItemId, quantity, checkoutID) =>
    dispatch(updateQuantityInCart(lineItemId, quantity, checkoutID));
  const removeLineItem = (checkoutId, lineItemId) =>
    dispatch(removeLineItemInCart(checkoutId, lineItemId));

  const optionsToVariant = (product, selectedOptions) =>
    variantForOptions(product, selectedOptions);

  return {
    products,
    product,
    featured,
    cartStatus,
    checkoutState,
    cartCount,
    shopDetails,
    optionsToVariant,
    addVariant,
    fetchProduct,
    fetchCollection,
    createCheckout,
    createShop,
    closeCart,
    openCart,
    updateQuantity,
    removeLineItem,
    setCount
  };
}
